export class RefDeserialize {

  static Retrocycle(obj: any): void {
    const catalog = new Map<string, any>();
    this.CatalogObject(obj, catalog);
    this.ResolveReferences(obj, catalog);
  }

  private static CatalogObject(obj, catalog: Map<string, any>) {

    // The catalogObject function walks recursively through an object graph
    // looking for $id properties. When it finds an object with that property, then
    // it adds it to the catalog under that key.

    let i: number;
    if (obj && typeof obj === 'object') {
      if (typeof obj.$id === 'string') {
        catalog.set(obj.$id, obj);
        delete obj.$id;
      }

      if (Object.prototype.toString.apply(obj) === '[object Array]') {
        for (i = 0; i < obj.length; i += 1) {
          this.CatalogObject(obj[i], catalog);
        }
      } else {
        for (const name in obj) {
          if (typeof obj[name] === 'object') {
            this.CatalogObject(obj[name], catalog);
          }
        }
      }
    }
  }

  private static ResolveReferences(obj: any, catalog: Map<string, any>) {

    // The resolveReferences function walks recursively through the object looking for $ref
    // properties. When it finds one that has a value that is an id, then it
    // replaces the $ref object with a reference to the object that is found in the catalog under
    // that id.

    let i: number;
    let item: any;

    if (obj && typeof obj === 'object') {
      if (Object.prototype.toString.apply(obj) === '[object Array]') {
        for (i = 0; i < obj.length; i += 1) {
          item = obj[i];
          if (item && typeof item === 'object') {
            if (typeof item.$ref === 'string') {
              obj[i] = catalog.get(item.$ref);
            } else {
              this.ResolveReferences(item, catalog);
            }
          }
        }
      } else {
        for (const name in obj) {
          if (typeof obj[name] === 'object') {
            item = obj[name];
            if (item) {
              if (typeof item.$ref === 'string') {
                obj[name] = catalog.get(item.$ref);
              } else {
                this.ResolveReferences(item, catalog);
              }
            }
          }
        }
      }
    }
  }
}
